'use client';

import Head from 'next/head';
import Script from 'next/script';

type CalendlyPopupProps = {
  children: React.ReactNode;
  url: string;
};

/**
 * Renders a Calendly popup component.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be rendered inside the component.
 * @param {string} props.url - The URL of the Calendly event.
 * @returns {JSX.Element} The rendered CalendlyPopup component.
 */
export function CalendlyPopup({ children, url }: CalendlyPopupProps) {
  const onClick = () => {
    window.Calendly.initPopupWidget({ url });
    // Note: This is to remove the scroll lock that Calendly adds
    //@ts-ignore
    document.body.style = 'overflow-y: scroll';
    return false;
  };

  return (
    <>
      <span className="cursor-pointer" onClick={onClick}>
        {children}
      </span>
      <Head>
        <link
          href="https://calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
      </Head>
      <Script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
        async
        strategy="afterInteractive"
      />
    </>
  );
}
