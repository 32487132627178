import Image from 'next/image';
import leah from '../images/customer-avatars/leah.png';
import amyjane from '../images/customer-avatars/amyjane.png';
import nicola from '../images/customer-avatars/nicola.png';

export default function AvatarStack() {
  return (
    <div className="flex -space-x-4 rtl:space-x-reverse">
      <Image
        className="h-10 w-10 rounded-full  border-white "
        src={leah}
        alt=""
        height={20}
        width={20}
      />
      <Image
        className="h-10 w-10 rounded-full  border-white "
        src={amyjane}
        alt=""
        height={20}
        width={20}
      />
      <Image
        className="h-10 w-10 rounded-full  border-white "
        src={nicola}
        alt=""
        height={20}
        width={20}
      />
      <a
        className="flex h-10 w-10 items-center justify-center rounded-full  border-white bg-gray-700 text-xs font-medium text-white hover:bg-gray-600 "
        href="#"
      >
        +15
      </a>
    </div>
  );
}
