import AvatarStack from './AvatarStack';
import StarRating from './StarRating';

export function AvatarStars() {
  return (
    <div className="inline-flex items-center justify-center gap-2 sm:justify-start">
      <AvatarStack />
      <div>
        <StarRating />
        <div>Customers Love Us</div>
      </div>
    </div>
  );
}
