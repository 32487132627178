export const calendlyConfig = {
  freeTrialRoutingForm:
    'https://calendly.com/d/3kj-ypb-6y9?hide_gdpr_banner=1&primary_color=057857&hide_gdpr_banner=1&primary_color=057857',
  freeTrialSinging:
    'https://calendly.com/musiceternal/free-trial-lesson?hide_gdpr_banner=1&primary_color=057857',
  freeTrialCatchAll:
    'https://calendly.com/musiceternal/free-trial-lesson?hide_gdpr_banner=1&primary_color=057857',
  freeTrialSongwriting:
    'https://calendly.com/musiceternal/free-trial-lesson?hide_gdpr_banner=1&primary_color=057857',
  freeTrialDrums:
    'https://calendly.com/adam-musiceternal/drum-free-trial?hide_gdpr_banner=1&primary_color=057857',
  freeTialUkulele:
    'https://calendly.com/musiceternal/free-trial-lesson?hide_gdpr_banner=1&primary_color=057857',
  freeTialPiano:
    'https://calendly.com/sepideh-wfhs/piano-free-trial?hide_gdpr_banner=1&primary_color=057857',
  freeTrialFlute:
    'https://calendly.com/sepideh-wfhs/flute-free-trial?hide_gdpr_banner=1&primary_color=057857',
  freeTrialClarinet:
    'https://calendly.com/jaymee-musiceternal/clarinet-free-trial?hide_gdpr_banner=1&primary_color=057857',
  freeTrialGuitar:
    'https://calendly.com/musiceternal/free-trial-lesson?hide_gdpr_banner=1&primary_color=057857',
};
